import React from "react";
import "../styles/Navbar.css";
import { Link, animatescroll as scroll } from "react-scroll";
import logo from "../assets/assinatura.png";

const Navbar = () => {
  const handleClose = () => {    
    var nav = document.getElementById("navbarNav");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };

  return (
    <header id="stickymenu">
      <nav class="navbar navbar-expand-lg fixed-top">
        <div class="container-fluid">
          <img
            src={logo}
            alt="/"
            class="navbar-brand"
            width="100"
            height="80"
          />

          <button
            class="navbar-toggler shadow-none"
            id="navbarBtn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              class="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>

          <div
            class="collapse navbar-collapse justify-content-md-end"
            id="navbarNav"
          >
            <ul class="navbar-nav justify-content-end text-end">
              <li class="nav-item">
                <Link
                  class="nav-link"
                  to="hero"
                  smooth={true}
                  duration={500}
                  onClick={handleClose}
                >
                  início
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class="nav-link"
                  to="about"
                  smooth={true}
                  offset={-80}
                  duration={500}
                  onClick={handleClose}
                >
                  sobre nós
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class="nav-link"
                  to="services"
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={handleClose}
                >
                  serviços
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class="nav-link"
                  to="clients"
                  smooth={true}
                  offset={0}
                  duration={500}
                  onClick={handleClose}
                >
                  clientes
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class="nav-link"
                  to="contact"
                  smooth={true}
                  offset={115}
                  duration={500}
                  onClick={handleClose}
                >
                  contato
                </Link>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="http://www.sistemavendas.n3inteligente.com.br"
                  target="_self"
                  rel="noopener noreferrer"
                >
                  sistema de vendas
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
