import React from "react";
import '../styles/Hero.css';

const Hero = () => { 
  
  return (
    <section id="hero" class="pt-5 vh-100">
      <div class="container pt-5">
        <div class="row justify-content-between px-3">
          <h1 class="display-1 fw-bolder col-10">
            Contabilidade <br />
            inteligente
          </h1>
          <p class="col-md-6">
            Dispomos de soluções tecnológicas para problemas contábeis,
            administrativos e financeiros, oferecendo aos nossos clientes um
            controle interno, com o qual poderão ter subsídios para tomada de
            decisões relevantes para as empresas. Focamos no desenvolvimento de
            ferramentas para otimizar serviços, fundamentar tomada de decisões e
            a saúde contábil e financeira do negócio.
          </p>
          <div class="col-md-6">
            <div class="row justify-content-end">
              <div class="col-md-4">
                <a  href="http://www.sistemavendas.n3inteligente.com.br" class="btn btn-dark" id="button1">
                  sistema de vendas
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
