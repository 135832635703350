import React from "react";
import "../styles/Clients.css";

import RNB from "../assets/SVG/RNBorrachasSVG.svg";
import ViaMalha from "../assets/SVG/ViaMalhaSVGColorido.svg";
import Unilar from "../assets/SVG/UnilarSVGColorido.svg";
import Lampadinha from "../assets/SVG/Lampadinha.jpg"
import AeE from "../assets/SVG/AeESVGColorido.svg";
import Compass from "../assets/SVG/CompassSVGColorido.svg";
import Clínica from "../assets/SVG/ClínicaSVGColorido.svg";
import Hangar from "../assets/SVG/Hangar42SVG.svg";
import Odimeire from "../assets/SVG/OdimeireSVG.svg";
import RnnR from "../assets/SVG/RnnRSVGColorido.svg";
import Medicina from "../assets/SVG/MedicinaSVGColorido.svg";

const Clients = () => {
  return (
    <section class="pt-5 min-vh-100" id="clients">
      <div class="container pt-5">
        <h3 class="fw-normal fs-6">Alguns de nossos clientes</h3>

        <div class="row text-center flex justify-content-between">
          <div
            class="card col-9 col-md-3 shadow mx-auto mb-4"
            id="carouselcard"
          >
            <div class="card-body">
              <div class="carouselimg" alt="..." id="RNBorrachas">
                <img src={RNB} alt="" />
              </div>
            </div>
          </div>
          <div
            class="card col-9 col-md-3 shadow mx-auto mb-4"
            id="carouselcard"
          >
            <div class="card-body">
              <div class="carouselimg" alt="..." id="ViaMalha"></div>
              <img src={ViaMalha} alt="" />
            </div>
          </div>
          <div
            class="card col-9 col-md-3 shadow mx-auto mb-4"
            id="carouselcard"
          >
            <div class="card-body">
              <div class="carouselimg" alt="..." id="Unilar">
                <img src={Unilar} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="row text-center flex justify-content-between">
        <div
            class="card col-9 col-md-3 shadow mx-auto mb-4"
            id="carouselcard"
          >
            <div class="card-body">
              <div class="carouselimg" alt="..." id="Lampadinha">
                <img id="LampadinhaImg" src={Lampadinha} alt="" />
              </div>
            </div>
          </div>
          <div
            class="card col-9 col-md-3 shadow mx-auto mb-4"
            id="carouselcard"
          >
            <div class="card-body">
              <div class="carouselimg" alt="..." id="AeE">
                <img src={AeE} alt="" />
              </div>
            </div>
          </div>
          <div
            class="card col-9 col-md-3 shadow mx-auto mb-4"
            id="carouselcard"
          >
            <div class="card-body">
              <div class="carouselimg" alt="..." id="Compass">
                <img src={Compass} alt="" />
              </div>
            </div>
          </div>
          
        </div>

        <div class="row text-center flex justify-content-between">
          <div
            class="card col-9 col-md-3 shadow mx-auto mb-4"
            id="carouselcard"
          >
            <div class="card-body">
              <div class="carouselimg" alt="..." id="Hangar42">
                <img src={Hangar} alt="" />
              </div>
            </div>
          </div>
          <div
            class="card col-9 col-md-3 shadow mx-auto mb-4"
            id="carouselcard"
          >
            <div class="card-body">
              <div class="carouselimg" alt="..." id="Odimeire">
                <img src={Odimeire} alt="" />
              </div>
            </div>
          </div>
          <div
            class="card col-9 col-md-3 shadow mx-auto mb-4"
            id="carouselcard"
          >
            <div class="card-body">
              <div class="carouselimg" alt="..." id="RnnR">
                <img src={RnnR} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="row text-center flex justify-content-between">
          <div
            class="card col-9 col-md-3 shadow mx-auto mb-4"
            id="carouselcard"
          >
            <div class="card-body">
              <div class="carouselimg" alt="..." id="Medicina">
                <img src={Medicina} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
