import React from "react";
import "../styles/About.css";
import textovazado from "../assets/TextoVazado.svg";
import grafismo from "../assets/grafismo.png";

const About = () => {
  return (
    <section id="aboutGrafismo" class="vh-100">
      <div class="container-fluid" id="aboutBg">
        <div id="about" class="container">
          <div class="row justify-content-between px-3">
            <div class="col-10 col-md-8">
              <h1 class="display-1 mt-5 pt-5 fw-bolder">
                +controle <br />
                +prosperidade
              </h1>
              <p id="about">
                Servimos como controle interno administrativo financeiro e
                contábil, com o qual clientes poderão ter mais subsídios para
                tomada de decisões relevantes para as empresas. O principal
                objetivo é minimizar a chance de erros e eventuais prejuízos
                para nossos clientes.
              </p>
              <span>
                <img class="img-responsive mt-3" src={textovazado} alt="/" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
