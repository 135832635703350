import React from 'react'
import "../styles/Contact.css"

const Contact = () => {
  return (
      <section id='contact' class="pt-5 pb-5">
          <div class="container mt-5 pt-2">
          <div class="container-fluid mt-5">
              <div class="row justify-content-between">
                <form action="" id="formEmail" class="col-md-6">
                  <div class="mb-3">
                    <label for="nome" class="form-label">nome completo</label>
                    <input
                      type="nome"
                      class="form-control"
                      id="nome"
                      placeholder="Digite seu nome"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="email" class="form-label">email</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="Digite seu email"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="corpoEmail" class="form-label">mensagem</label>
                    <textarea
                      class="form-control"
                      id="corpoEmail"
                      rows="3"
                      placeholder="Digite sua mensagem"
                    ></textarea>
                  </div>
                  <button
                    class="btn"
                    type="submit"
                    id="button2"
                    onclick="sendEmail();"
                  >
                    Enviar
                  </button>
                </form>

                <h1 class="display-1 col-md-6 fw-bolder text-end" >
                  vamos nos <span id="span-services">reunir</span>.
                </h1>
              </div>
            </div>
         </div>
          
    </section>
  )
}

export default Contact